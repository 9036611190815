import React, { FC } from "react";
import Layout from "../components/Layout";
import { Routes } from "../constants/routes";
import SEO from "../components/Seo";
import useMarkdown from "../hooks/useMarkdown";
import styled from "styled-components";

const Content = styled.div`
  padding: var(--s12);
  a,
  a:visited,
  a:hover {
    color: currentColor;
  }
  h2 {
    margin-bottom: var(--s4);
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  @media screen and (max-width: 700px) {
    padding: var(--s4);
  }
  a {
    font-size: 1.6rem;
    line-height: 2.4rem;
    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
    text-decoration: underline;
  }
  li,
  p {
    font-weight: 300;
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  > div > ul {
    max-width: 1500px;
    > li {
      display: grid;
      grid-template-columns: 1fr 3fr;
      border-top: 1px solid var(--grey);
      padding: var(--s2) 0;
    }
  }
`;

const AusstellungPage: FC = () => {
  const { html } = useMarkdown("ausstellungen")[0];

  return (
    <Layout activePage={Routes.AUSTELLUNGEN}>
      <SEO title="Biografie" />
      <Content>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Content>
    </Layout>
  );
};

export default AusstellungPage;
